import TicketShop from "components/TicketShop";
import { endOfDay } from "date-fns";
import React, { useEffect } from "react";
import Helmet from "react-helmet";

export default function ParklifePage(p) {
  const title = "Parklife Festival 2023 | Manchester Students Group";
  const description =
    "Manchester festival favourite: Parklife, check out the line up & prices";

  // useEffect(() => {
  //   window["fixrApiCallback"] = (api) => {
  //     window["fixrApiCallback"] = () => {};
  //     api.setFilter({
  //       before: Math.round(endOfDay(Date.now()).valueOf() / 1000),
  //     });
  //   };
  // }, []);

  return (
    <main className="event-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <h1>Parklife Festival 2023</h1>
      <h3>
        Manchester festival favourite: Parklife, check out the line up & prices
      </h3>
      <div className="events-page__today">
        <TicketShop
          scriptString={`
            <script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-fixr-shop-id="d05ebe7c-d551-4f7a-b08b-ade4852d5b37" data-fixr-theme="dark"></script>
          `}
        />
      </div>
    </main>
  );
}
